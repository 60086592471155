import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes : Array<RouteRecordRaw> = [
  // Home
  { name : "home", component : () => import("@/views/Home_v2.vue"), path : "/", },
  
  // Home v2
  // { name : "home_v2", component : () => import("@/views/Home_v2.vue"), path : "/new", },
  
  // Partenaires
  {
    name : "partners",
    path : "/partenaires",
    component : () => import("@/views/Partners.vue"),
  },
  
  // Calculateur
  {
    name : "calculateur",
    path : "/calculateur-de-volume",
    component : () => import("@/views/Calculateur.vue"),
  },
  
  // Cartons
  {
    name : "cartons",
    path : "/carton-de-demenagement",
    component : () => import("@/views/Cartons.vue"),
  },
  
  // Contact
  {
    name : "contact",
    path : "/contact",
    component : () => import("@/views/Contact.vue"),
  },
  
  // Guide Demenagement
  {
    name : "guide-dem-list",
    path : "/guide-demenagement-sommaire",
    component : () => import("@/views/Guides/Demenagement/Index.vue"),
  },
  {
    name : "guide-dem-category",
    path : "/guide-demenagement-:slug-c:category(\\d+)",
    component : () => import("@/views/Guides/Demenagement/List.vue"),
  },
  {
    name : "guide-dem-article",
    path : "/guide-demenagement-:slug-x:id(\\d+)",
    component : () => import("@/views/Guides/Demenagement/Detail.vue"),
  },
  
  // Guide GM
  {
    name : "guide-gm-list",
    path : "/guide-du-garde-meuble",
    component : () => import("@/views/Guides/GM/Index.vue"),
  },
  {
    name : "guide-gm-page",
    path : "/guide-:slug",
    component : () => import("@/views/Guides/GM/List.vue"),
  },
  
  // Departement
  {
    name : "departement-list",
    path : "/garde-meuble-departement",
    component : () => import("@/views/Departement/List.vue"),
  },
  {
    name : "departement-show",
    path : "/garde-meuble-:departement-departement-:num(\\d+)",
    component : () => import("@/views/Departement/Detail.vue"),
  },
  
  // Region
  {
    name : "region-list",
    path : "/garde-meuble-region",
    component : () => import("@/views/Region/List.vue"),
  },
  {
    name : "region-show",
    path : "/garde-meuble-:region-region-:id(\\d+)",
    component : () => import("@/views/Region/Detail.vue"),
  },
  
  // Basic search
  {
    name  : "search-basic",
    path  : '/garde-meuble-:city-:postcode(\\d+)-r:page(\\d+)?',
    alias : [
      '/garde-meuble-:city-:postcode(\\d+)-r:page(\\d+)?',  // Full
      '/garde-meuble-:city-:postcode-r(\\d+)',              // City + CP
      '/garde-meuble-:city-r:page(\\d+)?',                  // City + Page
      '/garde-meuble-:city-r',                              // City Only
      '/location-garde-meuble-:city-:postcode(\\d+)',       // Old (used in some old editables)
    ],
    props: { tarif : false },
    component : () => import("@/views/Search.vue"),
  },
  
  // Tarif search
  {
    name  : "search-tarif",
    path  : '/tarif-garde-meuble-:city-:postcode(\\d+)-r:page(\\d+)?',
    alias : [
      '/tarif-garde-meuble-:city-:postcode(\\d+)-r:page(\\d+)?',  // Full
      '/tarif-garde-meuble-:city-:postcode(\\d+)-r',              // City + CP
      '/tarif-garde-meuble-:city-r:page(\\d+)?',                  // City + Page
      '/tarif-garde-meuble-:city-r',                              // City Only
    ],
    props: { tarif : true },
    component : () => import("@/views/Search.vue"),
  },
  
  // Editables pages
  { name: "editable-1",  component: () => import("@/views/Editable.vue"), props: { page_id: 1,  tarif: false, show_search: true }, path : '/costockage-particulier' },
  { name: "editable-2",  component: () => import("@/views/Editable.vue"), props: { page_id: 2,  tarif: false, show_search: true }, path : '/cave-particulier' },
  { name: "editable-3",  component: () => import("@/views/Editable.vue"), props: { page_id: 3,  tarif: true,  show_search: true }, path : '/tarif-garde-meuble' },
  { name: "editable-4",  component: () => import("@/views/Editable.vue"), props: { page_id: 4,  tarif: false, show_search: true }, path : '/stockage-etudiant' },
  { name: "editable-5",  component: () => import("@/views/Editable.vue"), props: { page_id: 5,  tarif: false, show_search: true }, path : '/self-stockage' },
  // { name: "editable-6",  component: () => import("@/views/Static.vue"),   props: { page_id: 6,  tarif: false }, path : '/carton-de-demenagement' },
  { name: "editable-7",  component: () => import("@/views/Editable.vue"), props: { page_id: 7,  tarif: false, show_search: true }, path : '/box-stockage.fr' },
  { name: "editable-8",  component: () => import("@/views/Editable.vue"), props: { page_id: 8,  tarif: false, show_search: true }, path : '/box-a-louer' },
  { name: "editable-9",  component: () => import("@/views/Editable.vue"), props: { page_id: 9,  tarif: false, show_search: true }, path : '/location-box-bordeaux' },
  { name: "editable-10", component: () => import("@/views/Editable.vue"), props: { page_id: 10, tarif: false, show_search: true }, path : '/location-box-toulouse' },
  { name: "editable-11", component: () => import("@/views/Editable.vue"), props: { page_id: 11, tarif: false, show_search: true }, path : '/location-box-lyon' },
  { name: "editable-12", component: () => import("@/views/Editable.vue"), props: { page_id: 12, tarif: false, show_search: true }, path : '/location-box-paris' },
  { name: "editable-13", component: () => import("@/views/Editable.vue"), props: { page_id: 13, tarif: false, show_search: true }, path : '/location-box-lille' },
  { name: "editable-14", component: () => import("@/views/Editable.vue"), props: { page_id: 14, tarif: false, show_search: true }, path : '/location-box-marseille' },
  { name: "editable-15", component: () => import("@/views/Editable.vue"), props: { page_id: 15, tarif: false, show_search: true }, path : '/location-box-nice' },
  { name: "editable-16", component: () => import("@/views/Editable.vue"), props: { page_id: 16, tarif: false, show_search: true }, path : '/location-box-montpellier' },
  
  // Static pages (Editable without search)
  { name: "editable-17", component: () => import("@/views/Editable.vue"), props: { page_id: 17, tarif: false, show_search: false }, path : '/a-propos' },
  { name: "editable-18", component: () => import("@/views/Editable.vue"), props: { page_id: 18, tarif: false, show_search: false }, path : '/conditions-generales' },
  { name: "editable-19", component: () => import("@/views/Editable.vue"), props: { page_id: 19, tarif: false, show_search: false }, path : '/plan-du-site' },
  
  // GM
  { name: "gm",  component: () => import("@/views/GM.vue"), path : '/box-garde-meuble-:city-:gm_name-:gm_id(\\d+)?' },
  
  // GM Owners
  { name: "owner-help",     component: () => import("@/views/Owners/Help.vue"), path : '/aide-aux-proprietaires-de-garde-meuble' },
  { name: "owner-submit",   component: () => import("@/views/Owners/Join.vue"), path : '/proposer-votre-garde-meuble' },
  { name: "owner-redirect", redirect : '/proposer-votre-garde-meuble', path  : '/help-owner', alias : [
      '/comment-ca-marche',
      '/pourquoi-nous-rejoindre',
      '/quavez-vous-a-gagner',
      '/combien-ca-coute',
      '/livres-blancs',
  ]},
  
  // Fallback with "true" redirection to home (no history)
  {
    path : "/:pathMatch(.*)*",
    component: () => import("@/views/404.vue")
  }
];

//
// Route::map( "carton",                          'GET|POST', '/carton-de-demenagement',                                 __DIR__ . "/views/view.cartons.php"       );
// Route::map( "contact",                         'GET|POST', '/contact',                                                __DIR__ . "/views/view.contact.php"       );
// Route::map( "sitemap",                         'GET|POST', '/plan-du-site',                                           __DIR__ . "/views/view.sitemap.php"       );
// Route::map( "comparateur",                     'GET|POST', '/comparateur',                                            __DIR__ . "/views/view.comparateur.php"   );
// Route::map( "calculateur",                     'GET|POST', '/calculateur-de-volume',                                  __DIR__ . "/views/view.calculateur.php"   );
// Route::map( "partenaires",                     'GET|POST', '/partenaires',                                            __DIR__ . "/views/view.partenaires.php"   );
// Route::map( "cgu",                             'GET|POST', '/conditions-generales',                                   __DIR__ . "/views/view.legal.php"         );
// Route::map( "louer-camion",                    'GET|POST', '/louer-un-camion',                                        __DIR__ . "/views/view.camion.php"        );
// Route::map( "about",                           'GET|POST', '/a-propos',                                               __DIR__ . "/views/view.about.php"         );

// // Propriétaires de GM
// Route::map( "help",                            'GET|POST', '/aide-aux-proprietaires-de-garde-meuble',                __DIR__ . "/views/view.owner.help.php"    );
// Route::map( "help-proposer",                   'GET|POST', '/proposer-votre-garde-meuble',                            __DIR__ . "/views/view.owner.new.php"     );
// Route::map( "help-comment",                    'GET|POST', '/comment-ca-marche',                                      __DIR__ . "/views/view.owner.new.php"     ); // Duplicate
// Route::map( "help-rejoindre",                  'GET|POST', '/pourquoi-nous-rejoindre',                                __DIR__ . "/views/view.owner.new.php"     ); // Duplicate
// Route::map( "help-gagner",                     'GET|POST', '/quavez-vous-a-gagner',                                   __DIR__ . "/views/view.owner.new.php"     ); // Duplicate
// Route::map( "help-combien",                    'GET|POST', '/combien-ca-coute',                                       __DIR__ . "/views/view.owner.new.php"     ); // Duplicate
// Route::map( "help-livres-blancs",              'GET|POST', '/livres-blancs',                                          __DIR__ . "/views/view.owner.new.php"     ); // Duplicate

// // Regions
// Route::map( "region",                          'GET|POST', '/garde-meuble-region',                                    __DIR__ . "/views/view.region.php" );
// Route::map( "region-detail",                   'GET|POST', '/garde-meuble-[N:reg_name]-region-[i:reg_id]',            __DIR__ . "/views/view.region.detail.php" );

// // Departement
// Route::map( "departement",                     'GET|POST', '/garde-meuble-departement',                               __DIR__ . "/views/view.departement.php" );
// Route::map( "departement-detail",              'GET|POST', '/garde-meuble-[N:dep_name]-departement-[N:dep_code]',     __DIR__ . "/views/view.departement.detail.php" );

// // Page Garde meuble
// Route::map( "gm-new",                          'GET|POST', '/box-garde-meuble-[N:city]-[N:gm_name]-[i:gm_id]',        __DIR__ . "/views/view.gm.new.php" );
// Route::map( "gm-new-nocity",                   'GET|POST', '/box-garde-meuble-[N:gm_name]-[i:gm_id]',                 __DIR__ . "/views/view.gm.new.php" );
// Route::map( "dm-details",                      'GET|POST', '/demenageur-[N:city]-[N:gm_name]-[i:gm_id]',              __DIR__ . "/views/view.gm.demenageur.php" );
// Route::map( "gm-details",                      'GET|POST', '/garde-meuble-[N:city]-[N:gm_name]-[i:gm_id]',            __DIR__ . "/views/view.gm.gardemeuble.php" );
// Route::map( "reservation",                     'GET|POST', '/devis-[N:gm_name]-[i:gm_id]-box-[i:box_id]',             __DIR__ . "/views/view.gm.gardemeuble.php" );

// // Guide déménagement
// Route::map( "guide-dem-index",                 'GET|POST', '/guide-demenagement-sommaire',                            __DIR__ . "/views/view.guide.dem.index.php"     );
// Route::map( "guide-dem-category",              'GET|POST', '/guide-demenagement-[N:cat_name]-c[i:cat_id]',            __DIR__ . "/views/view.guide.dem.category.php"  );
// Route::map( "guide-dem-article",               'GET|POST', '/guide-demenagement-[N:art_name]-x[i:art_id]',            __DIR__ . "/views/view.guide.dem.article.php"   );

// // Guide garde-meuble
// Route::map( "guide-gm-index",                  'GET|POST', '/guide-du-garde-meuble',                                  __DIR__ . "/views/view.guide.gm.index.php" );
// Route::map( "guide-gm-bases",                  'GET|POST', '/guide-bases',                                            __DIR__ . "/views/view.guide.gm.article.php" );
// Route::map( "guide-gm-taille",                 'GET|POST', '/guide-taille',                                           __DIR__ . "/views/view.guide.gm.article.php" );
// Route::map( "guide-gm-taille-box",             'GET|POST', '/guide-taille-box',                                       __DIR__ . "/views/view.guide.gm.article.php" );
// Route::map( "guide-gm-prix",                   'GET|POST', '/guide-prix',                                             __DIR__ . "/views/view.guide.gm.article.php" );
// Route::map( "guide-gm-condition",              'GET|POST', '/guide-condition',                                        __DIR__ . "/views/view.guide.gm.article.php" );
// Route::map( "guide-gm-stockage",               'GET|POST', '/guide-stockage',                                         __DIR__ . "/views/view.guide.gm.article.php" );
// Route::map( "guide-gm-demenagement",           'GET|POST', '/guide-demenagement',                                     __DIR__ . "/views/view.guide.gm.article.php" );
// Route::map( "guide-gm-securite",               'GET|POST', '/guide-securite',                                         __DIR__ . "/views/view.guide.gm.article.php" );
// Route::map( "guide-gm-comparatif",             'GET|POST', '/guide-comparatif',                                       __DIR__ . "/views/view.guide.gm.article.php" );
// Route::map( "guide-gm-utilisation",            'GET|POST', '/guide-utilisation',                                      __DIR__ . "/views/view.guide.gm.article.php" );
// Route::map( "guide-gm-industrie",              'GET|POST', '/guide-industrie',                                        __DIR__ . "/views/view.guide.gm.article.php" );
// Route::map( "guide-gm-article",                'GET|POST', '/guide-article-[i:art_id]',                               __DIR__ . "/views/view.guide.gm.article.php" );



const router = createRouter( {
  history : createWebHistory( process.env.BASE_URL ),
  routes
} );

export default router;
